<template>
      <div class="single-floor-page">
            <div class="single-floor-header row md:justify-content-between">
                  <div :class="[showCompare? 'col-10' : 'col-12']" class="md:col-2 md:justify-content-start d-flex justify-content-between align-items-center">
                        <Btn @click="goTo('/floorplans')" class="arrow cursor-pointer" size="sm" variant="black" icon="arrow-left"/>
                        <span v-show="!showCompare" class="model-name ml-10">{{item.name}}</span>
                  </div>
                  <div v-show="!showCompare" class="col-12 md:col-5 d-flex justify-content-between md:justify-content-end mt-20">
                        <Btn class="scan mr-20" size="md" variant="black" icon="scan" title="Add to compare" @click="showCompare = true"/>
                        <Btn class="email" size="md" variant="black" icon="email" title="Send via email" @click="showModal = true"/>
                  </div>
                  <div class="right-side p-5 col-6 d-flex justify-content-between" v-if="isShowHeader">
                        <div class="mr-20 col-6">
                              <Input @input="filterItems" bgColor="#00000014" icon="search" placeholder="Search by unit name" v-show="showCompare"/>
                        </div>
                        <div class="col-4 d-flex justify-content-center align-items-center lg:justify-content-end" v-show="showCompare">
                              <span class="cancel-compare" @click="showCompare = false">Cancel</span>
                        </div>
                  </div>
            </div>
            <span class="abs-title d-none md:d-block ml-10">Floorplans</span>

            <SingleFloor v-if="!showCompare"/>
            <CompareFloor :item="{...item, hasHeaderAction: true}" v-else/>

            <modal :title="item.name" :active="showModal" @close="close()" v-if="showModal">
                  <template slot="body">
                        <img class="image-modal" :src="item.image" alt="item"/>
                        <div class="plan-metrics">
                              <div class="metric mr-50">
                                    <img src="@/assets/icons/svg/dark/resize.svg" alt="resize">
                                    <span class="title ml-15">{{item.size}} sq.ft</span>
                              </div>
                              <div class="metric mr-50">
                                    <img src="@/assets/icons/svg/dark/room.svg" alt="room">
                                    <span class="title ml-15">{{item.bedroom}}</span>
                              </div>
                              <div class="metric">
                                    <img src="@/assets/icons/svg/dark/shower.svg" alt="shower">
                                    <span class="title ml-15">{{item.bathroom}}</span>
                              </div>
                        </div>
                  </template>
                  <template slot="modalFooter">
                        <div class="full-width row d-flex align-items-center lg:justify-content-center">
                              <div class="col-3 mr-20">
                              <Input id="email-input" v-model="email" bgColor="#00000014" icon="search" placeholder="Enter your email address."/>
                              </div>
                              <div class="email-box col-1">
                                    <Btn @click="sendEmail" class="send-email text-center full-width" variant="black" icon="email" title="Send"/>
                              </div>
                        </div>
                  </template>
            </modal>
      </div>
</template>

<script>
      import Modal from "@/components/base/modal/modal";
      import SingleFloor from '@cp/singleFloor/singleFloor';
      import CompareFloor from '@cp/singleFloor/compareFloor/compareFloor';
      import Btn from '@b/button/Button';
      import Input from '@b/input/Input';
      import { getUnitById } from "@api/axios/unit.service";
      import { sendEmail } from "@api/axios/floor.service";
      import { bus } from '@m'

      export default {
            name: 'single-floor',
            components: {SingleFloor, CompareFloor, Btn, Modal, Input},
            data(){
                  return {
                        showModal: false,
                        showCompare: false,
                        item :{},
                        email: '',
                        isShowHeader: true
                  }
            },
            methods:{
                  close(){
                        this.showModal = false
                  },
                  getFloor(){
                        getUnitById(this.$route.params.id).then((res) => {
                              this.item = res.data.data;
                        }).catch(e => {
                              console.log(e);
                        })
                  },
                  filterItems(val){
                        bus.$emit('filterItemsByName', val.toUpperCase())
                  },
                  sendEmail(){
                        sendEmail({email: this.email, unit_id: this.item.id }).then(res => {
                              // console.log()
                              this.$root.$emit('notify', {state: res.data.message.includes('Success') ? 'success' : 'danger', message: res.data.message , time: 2})      
                        }).catch(e => {
                              console.log(e)
                        })

                  }
            },
            mounted(){
                  this.getFloor()
            }
      }
</script>

<style lang='scss'>
.single-floor-page {
      border-top: 1px solid $mainBorderColor;
      height: 100%;
      padding: 20px 10px 20px 50px;

      .modal{
            input[type="text"]{
                  border: none!important;
                  border-radius: 16px!important;
                  background-color: #e0e0e0!important;
                  font-size: 18px!important;
                  padding-left: 20px!important;
                  padding-right: 20px!important;
                  font-family: 'Vaud';
      
                  &::placeholder{
                        padding-left: 0!important;
                        font-size: 14px!important;
                  }
                  & + img{
                        display: none;
                  }
            }
      }

      .single-floor-header {
            .model-name {
                  @include toRem(font-size, 45);
                  font-family: $vaud-semi;
                  z-index: 99;
                  color: $white;

                  @media screen and (max-width: 1366px){
                        @include toRem(font-size, 33);
                  }
            }

            .cancel-compare{
                  display: flex;
                  font-family: $vaud-semi;
                  text-decoration: underline;
                  justify-content: center;
                  align-items: center;
                  color: $white;
                  margin-right: 20px;
                  font-size: 18px;
                  cursor: pointer;
            }

            .scan, .email {
                  padding: 15px;

                  @media screen and (max-width: 400px){
                        @include toRem(font-size, 12);
                  }
            }

            .arrow {
                  position: relative;
                  z-index: 100;
                  width: 60px;
                  height: 60px;
                  
                  @media screen and (max-width: 1366px){
                        padding: 10px;
                        width: 45px;
                        height: 45px;
                        border-radius: 16px !important;
                  }

                  .wrapper {
                        display: flex;
                        justify-content: center;
            
                        span {
                              display: none;
                        }
                  }
            }
      }

      .modal {
            .plan-metrics {
                  display: flex;
                  width: 100%;
                  justify-content: center;
                  font-family: $open-sans;
                  font-weight: 500;
                  .title{
                        font-family: 'Vaud';
                  }

                  .metric {
                        position: relative;
                        display: flex;
                        align-items: center;

                        &:not(:last-child):after {
                                    content: "|";
                                    opacity: 20%;
                                    position: absolute;
                                    right: -38px;
                                    @media screen and (max-width: 1366px) {
                                          right: -32px;
                                    }
                              }

                        span {
                              &:not(:last-child):after {
                                    content: "|";
                                    opacity: 20%;
                                    position: absolute;
                                    right: -38px;
                                    @media screen and (max-width: 1366px) {
                                          right: -32px;
                                    }
                              }
                        }
                  }
            }

            .email-box {
                  .send-email {
                        padding: 9px;
                        font-size: 18px;
                        border-radius: 20px !important;
                        width: 92% !important;

                        @media screen and (max-width: 1366px) {
                              width: 100% !important;
                        }
                  }
            }
      }

      .abs-title {
            position: absolute;
            top: 70px;
            left: 50px;
            width: 572px;
            height: 159px;
            @include toRem(font-size, 100);
            color: $black;
            opacity: .1;
            font-family: $vaud-bold;
      }

      .right-side {
            .input__effect > input {
                  padding: 14px 45px !important;
                  border-radius: 24px;
                  color: #000;
                  &::placeholder{
                        color: #000;
                        opacity: 0.3;
                        padding-left: 0!important;
                        font-size: 18px !important;
                  }
            }
      }
}
</style>