<template>
      <div class="row full-width single-floorplan">
            <div class="mini-floors col-6 md:col-3 d-none md:d-flex flex-column" :class="{ 'align-items-center justify-content-center' : item.models.length && item.models.length < 4 }">
                  <MiniFloor :item="item" v-for="(item, index) in item.models" :key="`${index}-floor`"/>
            </div>
            <div class="single-image col-12 md:col-9 md:pl-20">
                  <Floor class="full-width full-height" :hasHeader="false" :item="item"/>
            </div>
      </div>
</template>

<script>
      import Floor from '@/components/common/floorplans/floor';
      import MiniFloor from './miniFloor.vue';
      import { getUnitById } from "@api/axios/unit.service";
      import { getUnits } from "@api/axios/unit.service";

      export default {
            name: 'single-floorplan',
            components: {
                  Floor,
                  MiniFloor
            },
            data(){
                  return {
                        item : {},
                        floors: []
                  }
            },
            methods: {
                  getFloor(){
                        getUnitById(this.$route.params.id).then((res) => {
                              res.data.data.models.sort((a, b) => {
                                    if ( parseInt(a.floor.name.replace( /^\D+/g, '')) < parseInt(b.floor.name.replace( /^\D+/g, '')) ){
                                          return -1;
                                    }
                                    if ( parseInt(a.floor.name.replace( /^\D+/g, '')) > parseInt(b.floor.name.replace( /^\D+/g, '')) ){
                                          return 1;
                                    }
                                    return 0;
                              })
                              this.item = res.data.data;
                        }).catch(e => {
                              console.log(e);
                        })
                  },
                  getFloorList(){
                        getUnits().then((res) => {
                              this.floors = res.data.data;
                        }).catch(e => {
                              console.log(e);
                        })
                  },

            },
            mounted(){
                  this.getFloor();
                  this.getFloorList();
            }
      }
</script>

<style lang='scss'>     
      @import 'singleFloor';
</style>