<template>
      <div class="mini-floor">
            <span>{{item.floor.name}}</span>
            <div class="img-container">
                  <img :src="item.image" :alt="item.floor.name"/>
            </div>
      </div>
</template>

<script>
      export default {
            name: 'mini-floor',
            props: {
                  item: {
                        type: Object,
                        default: ()=> {}
                  }
            }
      }
</script>

<style lang='scss'>
.mini-floor {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 15px;
      margin-right: -15px;
      padding: 0 15px;
      width: 100%;

      &:first-of-type {
            margin-top: 15px;
      }
      .img-container {
            background: $white;
            border-radius: 20px;
            width: 76%;
            height: 177px;
            display: flex;
            justify-content: center;
            img {
                  max-width: 100%;
                  width: 60%;
                  margin: 10px;
            }
      }
}
</style>