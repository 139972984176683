<template>
      <div class="compare row md:align-items-baseline" :class="{'is-comparing-item': comparingItem}" :style="{'justify-content': 'space-around'}">
            <div class="left-side-floor col-12 md:col-6 mb-20 md:mb-0" :style="{'margin-top': comparingItem? '0' : null}">
                  <floor :class="{'has-comaring-item': comparingItem}" class="full-width full-height" hasHeader :item="item">
                        <template v-if="item.hasHeaderAction" slot="headerAction">
                              <Btn class="email" size="md" variant="black" icon="email" title="Send via email" @click="handleModalShow(item)"/>
                        </template>
                  </floor>
            </div>
            <div class="col-12 md:col-6" >
                  <FloorPlanList v-if="!comparingItem" fillRow style="margin-top:0 !important" @setFloorItem="setFloorItem"/>
                  <floor v-else class="full-width full-height" hasHeader :item="{...selectedItemToCompare, hasHeaderAction: true}">
                        <template v-if="item.hasHeaderAction" slot="headerAction">
                              <Btn class="email" size="md" variant="black" icon="email" title="Send via email" @click="handleModalShow(selectedItemToCompare)"/>
                        </template>
                  </floor>
            </div>
            <modal :title="selectedItem.name" :active="showModal" @close="close()" v-if="showModal">
                  <template slot="body">
                        <img class="image-modal" :src="selectedItem.image" alt="item"/>
                        <div class="plan-metrics">
                              <div class="metric mr-50">
                                    <img src="@/assets/icons/svg/dark/resize.svg" alt="resize">
                                    <span class="title ml-15">{{selectedItem.size}} sq.ft</span>
                              </div>
                              <div class="metric mr-50">
                                    <img src="@/assets/icons/svg/dark/room.svg" alt="room">
                                    <span class="title ml-15">{{selectedItem.bedroom}}</span>
                              </div>
                              <div class="metric">
                                    <img src="@/assets/icons/svg/dark/shower.svg" alt="shower">
                                    <span class="title ml-15">{{selectedItem.bathroom}}</span>
                              </div>
                        </div>
                  </template>
                  <template slot="modalFooter">
                        <div class="full-width row d-flex align-items-center lg:justify-content-center">
                              <div class="col-3 mr-20">
                                    <Input id="email-input" v-model="email" bgColor="#00000014" icon="search" placeholder="Enter your email address."/>
                              </div>
                              <div class="email-box col-1">
                                    <Btn @click="sendEmail" class="send-email text-center full-width" variant="black" icon="email" title="Send"/>
                              </div>
                        </div>
                  </template>
            </modal>
      </div>
</template>

<script>
      import FloorPlanList from '@/components/pages/floorplans/SingleFloorXL';
      import Floor from '@/components/common/floorplans/floor';
      import Btn from '@b/button/Button';
      import Modal from "@/components/base/modal/modal";
      import Input from '@b/input/Input';
      import { sendEmail } from "@api/axios/floor.service";

      export default {
            name: 'compare',
            components: { Floor, Btn, FloorPlanList, Modal, Input },
            props: {
                  item :{
                        type: Object,
                        default: ()=>{}
                  }
            },
            data(){
                  return {
                        // item : { model: 'Model 01', name: '302', bedrooms: 1, bathrooms: 3, sq: 35, hasHeaderAction: true },
                        comparingItem: false,
                        selectedItemToCompare: {},
                        selectedItem: {},
                        showModal: false,
                        email: ''
                  }
            },
            watch: {
                  showModal(val){
                        if(val) {
                              document.querySelector('body').style.overflowY = 'hidden';
                        }else {
                              document.querySelector('body').style.overflowY = 'scroll';
                        }
                  }
            },
            methods: {
                  close(){
                        this.showModal = false
                  },
                  handleModalShow(item){
                        this.selectedItem = item;
                        this.showModal = true
                  },
                  setFloorItem(item){
                        console.log(item)
                        this.selectedItemToCompare = item;
                        this.comparingItem = true;
                        this.$parent.isShowHeader = false;
                  },
                  resizeHandler(){
                        if(this.showModal) return
                        if((window.matchMedia("(max-width: 768px)").matches) || (window.matchMedia("(max-height: 820px)").matches)) {
                              document.querySelector('body').style.overflowY = 'scroll';
                        }
                  },
                  sendEmail(){
                        sendEmail({email: this.email, unit_id: this.item.id }).then(res => {
                              this.$root.$emit('notify', {state: res.data.message.includes('Success') ? 'success' : 'danger', message: res.data?.errors ? res.data?.errors[0] : 'Sumbit completed Succesfully.', time: 2000})      
                        }).catch(e => {
                              console.log(e)
                        })

                  }
            },
            mounted(){
                  if((window.matchMedia("(max-width: 768px)").matches) || (window.matchMedia("(max-height: 820px)").matches)) {
                        document.querySelector('body').style.overflowY = 'scroll';
                  }
                  window.addEventListener('resize', this.resizeHandler)
            },
            beforeDestroy(){
                  if((window.matchMedia("(max-width: 768px)").matches) || (window.matchMedia("(max-height: 820px)").matches)) {
                        document.querySelector('body').style.overflowY = 'hidden';
                  }
                  window.removeEventListener('resize', function(){})
            }
      }
</script>

<style lang='scss'>
      // #app {
      //       overflow: hidden;
      // }
      .compare {
            display: flex;
            justify-content: center;
            margin-top: 45px;

            @media screen and (max-width:1366px){
                  margin-top: 45px !important;
            }

            .left-side-floor {

                  .has-comaring-item {
                        .card {
                              &::after {
                                    height: 100vh !important;
                              }
                        }  
                  }
                  .card {
                        &::after {
                              @media screen and (max-width: 1366px){
                                    height: 112vh !important;
                              }
                              content: "";
                              width: 1.5px;
                              height: 100vh;
                              background: #ffffff30;
                              position: absolute;
                              right: -18px;
                              top: -125px;
                        }
                  }
            }

            .col-6:first-child{
                  position: relative;
                  margin-right: 20px;
            }

            .col-6:last-child{
                  margin-left: 20px;
            }

            .models{
                margin-right: -63px;  
            }

            .unique-floor.full-width{
                  padding-right: 32px;
                  .card-header{
                        padding: 1rem 1rem 6px 1rem;
                  }
            }

            @media (min-width: 1400px) {
                  .models{
                        margin-right: 0;  
                  }
                  .unique-floor.full-width{
                        .card{
                              height: 930px;
                        }
                  }
            }

            .email-box {
                  .send-email {
                        padding: 9px;
                        font-size: 18px;
                        border-radius: 20px !important;
                        width: 92% !important;
                  }
            }
      }

      @media(max-width:2200px){
            .compare {
                  .unique-floor.full-width {
                        .card{
                              height: 730px;
                        }
                        .card-body{
                              height: 95%;
                        }
                  }
            }
      }
</style>